@use '../config/' as *;
.team-bar,
.player-bar {
  height: .4rem;
  @extend %flex;
  @extend %half-radius;
  @extend %c-white-bg-1;
  &-fill {
    height: .4rem;
    @extend %half-radius;
    @extend %flex;
    @extend %c-white-bg-10;
  }
  &.won {
    .team-bar-fill {
      @extend %c-orange-bg-10;
    }
  }
}
.team-bar:first-child,
.team-bar:nth-child(2) {
  justify-content: flex-end;
}
.progress-item {
  &:first-child {
    .player-bar {
      justify-content: flex-end;
    }
  }
  &.won {
    .player-bar-fill {
      @extend %c-orange-bg-10;
    }
  }
}