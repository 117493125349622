@use '../config/' as *;
@forward "../custom/progress-bar";

.waf-compareplayer {
    margin-block: 0;
    @extend %p-y-two-space;
    @extend %m-x-half-neg;
    // @extend %c-blue-bg-10;
    @extend %relative;
    &::before {
        content: '';
        background: url('/static-assets/images/cssimages/headtohead-mob-bg.png?v=#{$image-version}') center / cover no-repeat;
        z-index: var(--zindex-head-to-head-bg);
        @extend %w-100;
        @extend %h-100;
        @extend %position-t-l;
    }
    .waf-head {
        @extend %m-b-two-space;
        .head-wrap {
            flex-direction: column;
        }
        .title,.sub-title {
            @extend %uppercase;
            @extend %font-14-pb;
        }
        .title {
            @extend %c-pure-white-10;
        }
        .sub-title {
            font-family: $font-pr;
            @extend %c-white-8;
        }
        .head-tab {
            @extend %d-none;
        }
    }
    .card-head-wrapper {
        gap: 1rem;
        @extend %flex-sb-n;
        @extend %relative;
        &::after {
            content: "vs";
            width: 3.5rem;
            aspect-ratio: 1;
            border-radius: 100vmax;
            top: 18.5rem;
            @extend %font-14-pb;
            @extend %c-blue-bg-10;
            @extend %c-white-10;
            @extend %flex-c-c;
            @extend %position-h-center;
        }
        .card {
            &-a {
                .card-thumbnail {
                    @extend %m-l-full-neg;
                }
                .card-info {
                    .card-info-item {
                        &:nth-child(1) {
                            .value {
                                padding-inline: 0 2.3rem;
                                flex-direction: row-reverse;
                            }
                            .image {
                                right: 0;
                                left: unset;
                            }
                        }
                    }
                }
                .card-search {
                    .form-input {
                        width: calc(100% + 2.4rem);
                        @extend %m-l-full-neg;
                        @extend %p-l-full;
                    }
                    &-list {
                        @extend %m-l-full-neg;
                    }
                }
            }
            &-b {
                .card-thumbnail {
                    @extend %m-r-full-neg;
                }
                .card-info {
                    align-items: flex-end;
                    .card-info-item {
                        @extend %flex-fe-n;
                        .label {
                            @extend %d-none;
                        }
                        .value {
                            justify-content: flex-end;
                            @extend %text-right;
                        }
                    }
                }
                .card-search {
                    .form-input {
                        width: calc(100% + 2.4rem);
                        margin-left: var(--half-space-negative);
                        @extend %m-r-full-neg;
                        @extend %text-right;
                        @extend %p-r-full;
                    }
                    &-wrapper {
                        .btn-link {
                            justify-content: flex-end;
                            &::before {
                                content: unset;
                            }
                            &::after {
                                content: '\e804';
                                font-family: $font-icon;
                                line-height: 1;
                                @extend %font-20-pr;
                                @extend %c-orange-10;
                            }
                        }
                    }
                    &-list {
                        @extend %m-r-full-neg;
                    }
                }
            }
            &-item {
                @include flex-config(flex,column);
                width: calc(50% - .6rem);
            }
            &-thumbnail {
                order: -1;
                height: 18.5rem;
                .image {
                    width: 100%;
                    height: 100%;
                    border-radius: 0;
                    object-fit: contain;
                    background: transparent;
                }
            }
            &-info {
                gap: var(--half-space);
                @extend %m-y-full;
                @extend %flex-column;
                .card-info-item {
                    width: calc(100% - 4rem);
                    @extend %flex-n-c;
                    &:nth-child(1) {
                        .value {
                            padding-left: 2.3rem;
                            line-height: 1.2;
                            @include truncate-vertical(1, 14, 17);
                            @extend %uppercase;
                            @extend %relative;
                        }
                        .image {
                            @include position-combo("y-center", null, 0);
                        }
                    }
                    .label {
                        @extend %capitalize;
                        @extend %position-h-center;
                        @extend %c-white-4;
                    }
                    .value {
                        gap: var(--half-space);
                        @extend %flex-n-c;
                        @extend %c-white-8;
                        @extend %text-left;
                        .image {
                            width: 1.5rem;
                            height: 1.5rem;
                        }
                    }
                }
            }
            &-search {
                height: 7.4rem;
                order: -1;
                @extend %relative;
                .form-input {
                    border: 0;
                    width: calc(100% + var(--full-space));
                    border-radius: var(--half-radius);
                    @extend %c-blue-2-bg-10;
                    @extend %p-x-zero;
                    @extend %font-14-pr;
                }
                &-wrapper {
                    @extend %flex-column;
                    .btn-link {
                        @extend %p-t-full;
                        gap: var(--half-space);
                        @extend %relative;
                        @extend %flex-n-c;
                        &::before {
                            content: '\e804';
                            font-family: $font-icon;
                            line-height: 1;
                            @extend %font-20-pr;
                            @extend %c-orange-10;
                        }
                        .btn-text {
                            @extend %capitalize;
                            @extend %font-14-pr;
                            @extend %c-orange-10;
                        }
                    }
                }
                &-list {
                    z-index: var(--zindexheadtoheadsearch);
                    border-radius: 0 0 var(--full-radius) var(--full-radius);
                    box-shadow: 0px 0px 41px 0px #020020;
                    margin-top: -.4rem;
                    @extend %d-none;
                    @include position(3.5rem,null,null,0);
                    @extend %c-blue-2-bg-10;
                    @extend %p-y-full;
                    @extend %p-x-full;
                    &.active {
                        display: block;
                        overflow-y: auto;                    
                    }
                    .search-item {
                        cursor: pointer;
                        position: relative;
                        &:not(:last-child) {
                            &::after {
                                content: '';
                                height: .1rem;
                                width: calc(100% + var(--two-space));
                                @include position(null,null,0,var(--full-space-negative));
                                @extend %c-blue-light-bg-2;
                            }
                        }
                        padding-block: 1rem;
                        @extend %flex-n-c;
                        .item {
                            &-info {
                              flex-wrap: wrap;
                              gap: var(--quarter-space) 0;
                              @extend %flex;
                            }
                            &-thumb {
                              width: 3rem;
                              height: 3rem;
                              flex-shrink: 0;
                              @extend %m-r-half;
                              .image {
                                @extend %c-pure-black-bg-2;
                                @extend %circle-radius;
                              }
                            }
                            &-logo {
                              font-size: 1.2rem;
                              gap: .5rem;
                              @extend %flex-n-c;
                              @extend %c-blue-1-10;
                              .image {
                                width: 1.5rem;
                                height: 1.5rem;
                                object-fit: cover;
                              }
                            }
                            &-title {
                              @extend %font-14-pr;
                              @extend %m-y-zero;
                              @extend %c-white-10;
                              @include truncate-vertical(1,14);
                            }
                        }
                    }
                }
            }
            &-action {
                width: auto;
                @extend %m-t-full;
                @extend %flex-fs-c;
                ~ .waf-select-box {
                    margin-top: var(--full-space);
                }
                .text {
                    @extend %c-orange-10;
                    @extend %capitalize;
                    @extend %font-14-pb;
                }
            }
        }
        .waf-select-box {
            margin-top: 5.4rem;
            @extend %m-b-full;
            @extend %relative;
        }
        .player-result-title {
            height: 4rem;
            border-bottom: .1rem solid hsl(var(--hsl-c-pure-white) / 0.2);
            font-size: 1.4rem;
            @extend %w-100;
            @extend %flex-sb-c;
            @extend %c-white-10;
            @extend %truncate-line;
            &::after {
              content: "\e806";
              font: 2.4rem/1 $font-icon;
              transition: rotate .3s ease-in;
            }
            &[aria-expanded="true"] {
              &::after {
                rotate: 180deg;
              }
              ~ .select-box-wrap {
                @extend %d-block;
              }
            }
            &.disabled {
                cursor: default;
                .btn-text {
                    @extend %c-white-6;
                }
            }
        }
        .select-box-wrap {
            width: 100%;
            border-radius: .2rem;
            z-index: var(--zindex-select-year);
            @extend %d-none;
            @extend %w-100;
            @extend %c-blue-2-bg-10;
            // @extend %c-grey-bg-10;
            @include position(100%);
            .list-item {
                @extend %text-left;
                @extend %w-100;
                @extend %p-half;
                @extend %c-white-10;
                @extend %truncate-line;
                &:not(:last-child) {
                    border-bottom: .1rem solid hsl(var(--hsl-c-pure-white) / 0.2);
                }
            }
            .dropdown-close {
                @extend %d-none;
            }
        }
        .card-content-wrapper {
            @extend %m-t-half;
            .player-result-item {
                gap: 1rem;
                @include flex-config(flex, column);
                .item {
                  padding-block: 1.2rem;
                  gap: 1.2rem;
                  @extend %flex-n-c;
                  &:first-child {
                    padding-top: 0;
                  }
                  &:last-child {
                    padding-bottom: 0;
                  }
                  &:not(:last-child) {
                    border-bottom: .1rem solid hsl(var(--hsl-c-pure-white) / 0.2);
                  }
                  &-wrapper {
                    padding: 2rem var(--full-space);
                    border: .1rem solid hsl(var(--hsl-c-pure-white) / 0.2);
                    gap: 1rem;
                    @extend %half-radius;
                    @extend %flex-column;
                    @extend %c-white-bg-1;
                    @extend %c-grey-10;
                  }
                  &-label {
                    @extend %font-14-pb;
                    @extend %capitalize;
                    @extend %c-grey-10;
                    @extend %text-center;
                  }
                  &-list {
                    .item-label {
                        @extend %text-left;
                        @extend %c-grey-10;
                        @extend %font-12-pr;
                    }
                  }
                }
                .progress {
                  &-view {
                    gap: .4rem;
                    display: grid;
                    grid-auto-columns: minmax(0, 1fr);
                    grid-auto-flow: column;
                    .number {
                      @extend %c-white-10;
                    }
                  }
                  &-item {
                    .player-score {
                      @extend %m-b-half;
                      @extend %text-center;
                    }
                  }
                }
                .label {
                  font-size: 1.2rem;
                  text-transform: capitalize;
                  @extend %d-block;
                }
                .item-number {
                    font: 2.4rem/1 $font-pb;
                  @extend %c-orange-10;
                }
                .number {
                  font: 2rem/1 $font-pb;
                  @extend %c-orange-10;
                }
                .progress-item {
                  &.win {
                    .number {
                      @extend %c-orange-10;
                    }
                    .player-bar-fill {
                      @extend %c-orange-bg-10;
                    }
                  }
                }
            }
        }
    }
    .item2, .item5 {
        .item-label {
            max-width: 10rem;
        }
    }
    .card-footer {
        @extend %m-y-full;
        @extend %flex-c-c;
        .btn-share {
            border-radius: 3.2rem;
            @extend %p-y-half;
            @extend %p-x-full;
            @extend %gap-half;
            @extend %flex-n-c;
            @extend %c-pure-white-bg-10;
            .btn-text {
                @extend %capitalize;
                @extend %font-16-pr;
                @extend %c-blue-10;
            }
            &:after {
                content: '\e816';
                font-family: $font-icon;
                @extend %c-blue-10;
                @extend %font-18-pb;
            }
        }
    }
}
.client-section {
    @extend %d-none;
}
@media (max-width: $small-mobile-max-width) {
    .waf-compareplayer .card-head-wrapper .card-content-wrapper .player-result-item .item-wrapper {
        padding: var(--full-space) var(--half-space);
    }
}
@media (min-width:$tablet-min-width) {
    .waf-compareplayer {
        padding-block: 8rem;
        &::before {
            content: '';
            background: url('/static-assets/images/cssimages/headtohead-bg.png?v=#{$image-version}') center / cover no-repeat;
        }
        .card-head-wrapper {
            .card {
                &-thumbnail {
                    margin-left: auto;
                    width: 18rem;
                    height: 20rem;
                    .image {
                        object-fit: cover;
                    }
                }
            }
        }
        .card-footer {
            margin-top: 4rem;
        }
    }
}
@media (min-width: $desktop-min-width) {
    .waf-compareplayer {
        .waf-head {
            .title {
                font-size: 2.4rem;
            }
            .sub-title {
                font-size: 2rem;
            }
        }
        .card-head-wrapper {
            gap: calc(var(--full-space)*4);
            &::after {
                font-size: 3.2rem;
                top: 20rem;
                width: 8.3rem;
                height: 5.6rem;
                border-radius: 100vmax;
            }
            .card {
                &-item {
                    position: relative;
                    flex-direction: row;
                    flex-wrap: wrap;
                    width: calc(50% - var(--two-space));
                }
                &-a {
                    .card-thumbnail {
                        margin-left: 0;
                    }
                    .card-search {
                        .form-input {
                            width: calc(100% + var(--full-space));
                            margin-left: 0;
                        }
                        &-list {
                            margin-left: 0;
                        }
                    }
                    .card-info {
                        padding-left: var(--one-n-half-space);
                        .card-info-item {
                            &:nth-child(1) {
                                .value {
                                    padding-inline: 0 3rem;
                                    right: unset;
                                    left: 0;
                                    flex-direction: row;
                                }
                            }
                        }
                    }
                }
                &-b {
                    .card-thumbnail {
                        order: 0;
                        margin-right: 0;
                    }
                    .card-search {
                        .form-input {
                            width: calc(100% + var(--full-space));
                            margin-inline: var(--full-space-negative) 0;
                        }
                        &-list {
                            margin-right: 0;
                        }
                        &-wrapper {
                            .btn-link {
                                left: var(--full-space);
                                right: unset;
                            }
                        }
                    }
                    .card-info {
                        order: -1;
                        padding-right: var(--one-n-half-space);
                        .card-info-item {
                            flex-direction: row-reverse;
                            justify-content: flex-start;
                            .label {
                                text-align: right;
                                display: block;
                            }
                            .value {
                                padding-inline: 3rem 0;
                            }
                        }
                    }
                    .card-action {
                        right: 20.4rem;
                        left: unset;
                        text-align: right;
                    }
                }
                &-info {
                    padding-top: 1.2rem;
                    justify-content: flex-start;
                    width: calc(100% - 18rem);
                    margin-block: 0;
                    .card-info-item {
                        gap: 2.4rem;
                        .label {
                            width: 7.5rem;
                            font-size: 1.8rem;
                            position: unset;
                            transform: unset;
                        }
                        .value {
                            .image {
                                width: 2rem;
                                height: 2rem;
                            }
                        }
                        &:nth-child(1) {
                            .value {
                                @include truncate-vertical(1, 18, 24);
                            }
                        }
                    }
                }
                &-action {
                    width: auto;
                    margin-top: 0;
                    @include position(16.8rem,null,null,20.4rem);
                }
                &-search {
                    order: 0;
                    width: 100%;
                    height: unset;
                    .form-input {
                        height: 5.5rem;
                        width: 100%;
                        font-size: 1.8rem;
                        padding: var(--full-space);
                    }
                    &-list {
                        top: 5.5rem;
                        width: 100%;
                        margin-top: var(--half-space-negative);
                        .search-item {
                            &:not(:last-child) {
                                &::after {
                                    left: 0;
                                    width: 100%;
                                }
                            }
                            .item-logo {
                                position: relative;
                                margin-left: var(--two-space);
                                &::before {
                                    content: '';
                                    width: .1rem;
                                    height: 80%;
                                    background: hsl(var(--hsl-c-blue-light)/0.2);
                                    @include position(null,null,null,var(--full-space-negative));
                                }
                            }
                        }
                    }
                    &-wrapper {
                        position: relative;
                        .btn-link {
                            position: absolute;
                            right: var(--full-space);
                            .btn-text {
                                font-size: 0;
                            }
                        }
                    }
                }
                &-content-wrapper {
                    width: 100%;
                }
            }
            .waf-select-box {
                width: 100%;
                margin-top: var(--full-space);
            }
            .player-result-title {
                font-size: 1.8rem;
                padding-inline: var(--full-space);
            }
            .select-box-wrap {
                .select-list {
                    @include flex-config(flex,column,null,flex-start);
                    .list-item {
                        padding: var(--full-space);
                    }
                }
            }
            .card-content-wrapper {
                .player-result-item {
                    gap: var(--full-space);
                    .item {
                        padding: 0 var(--half-space);
                        position: relative;
                        flex-direction: column;
                        &:not(:last-child) {
                            border-bottom: 0;
                            &::after {
                            content: "";
                            width: .1rem;
                            height: 100%;
                            background-color: hsl(var(--hsl-c-pure-white) / 0.2);
                            @include position-combo("tr");
                            }
                        }
                        &-wrapper {
                            padding-inline: var(--one-n-half-space);
                        }
                        &-label {
                            font-size: 1.6rem;
                            font-weight: 700;
                        }
                        &-number {
                            font-size: 3.6rem;
                        }
                        &-list {
                            display: grid;
                            grid-auto-flow: column;
                            grid-auto-columns: minmax(0, 1fr);
                            .item-label {
                                font-size: 1.6rem;
                                font-weight: 700;
                                text-align: center;
                            }
                        }
                    }
                    .item2, .item5 {
                        .item-label {
                            text-align: left;
                        }
                    }
                    .label {
                        font-size: 1.4rem;
                    }
                    .number {
                        font-size: 2.4rem;
                    }
                    .progress {
                        &-item {
                            display: flex;
                            align-items: center;
                            &.lost {
                                flex-direction: row-reverse;
                            }
                            &:last-child {
                                .player-score {
                                    padding-right: 0;
                                    padding-left: var(--full-space);
                                    text-align: right;
                                }
                            }
                            .player-score {
                                text-align: left;
                                padding-right: var(--full-space);
                            }
                        }
                    }
                }
                .player-bar {
                    flex-grow: 1;
                }
            }
        }
        .card-head-wrapper .card-content-wrapper .player-result-item {
            .item2 {
                position: relative;
                .item-list {
                    display: flex;
                    justify-content: space-between;
                }
                .item {
                    max-width: 11rem;
                    padding-inline: 0;
                    position: unset;
                    align-items: unset;
                    text-align: left;
                    &::after {
                        height: calc(100% - 4rem);
                        top: 2rem;
                        right: unset;
                    }
                    &:first-child::after {
                        left: 33.33%;
                    }
                    &:nth-child(2)::after {
                        left: 66.67%;
                    }
                }
            }
            .item5 {
                position: relative;
                .item-list {
                    display: flex;
                    justify-content: center;
                    gap: 9.6rem;
                }
                .item {
                    max-width: 9rem;
                    padding-inline: 0;
                    position: unset;
                    align-items: unset;
                    text-align: left;
                    &:first-child::after {
                        height: calc(100% - 4rem);
                        top: 2rem;
                        left: 50%;
                    }
                }
            }
        }
    }
}